import { useState, useEffect, useContext, createContext } from "react";

import axios from "axios";
import { io } from 'socket.io-client';

import departures_data from '../data/departure.js';
import city_data from "../data/data.js";
const DepartureContext = createContext();
const filter_str = [
    "British Airways",//ok
    "Air France",//OK
    "Alitalia",//
    "SWISS",//ok
    "South Africa Airways",//
    "Ghana Airways",//
    "Ethiopian Airlines",//ok
    "American Airlines",//ok
    "Delta Air lines",//ok
    "KLM",//ok
    "Royal Air Maroc",//ok
    "Air India",//
    "Air Cote Divoire",//
    "EMIRATES",//
    "Singapore Airlines",//
    "Qatar Airways",//ok
    "Turkish Airlines",//ok
    "Air Peace",
    "Lufthansa"//ok
];

const DepartureProvider = ({ children }) => {
    //company name,Departure,image_path,airport
    const [idata, setidata] = useState([]);

    useEffect(() => {
        updateData();
        setInterval(() => {
            updateData();
        }, 600000);

        // let temp = [...departures_data.flightRecords.filter(val => filter_str.findIndex((str) => str === val.airlineName) !== -1)];
        // temp.sort(function (a, b) { return (new Date(a.scheduledTime) - new Date(b.scheduledTime)) });
        // temp.reverse();
        // setidata(temp);
    }, [])


    const updateData = () => {
        fetchDataWithToken();
    }

    const fetchDataWithToken = async () => {
        // let method = false;
        const token = await getClientCredentialsToken();
        if (!token) {
            console.log('Failed to retrieve access token.');
            return;
        }

        const apiUrl = 'https://sitaopen.api.aero/fids/v1/LOS/D?includeAirlines=BA%2CAF%2CLX%2CSA%2CET%2CAA%2CDL%2CUA%2CKL%2CAT%2CAI%2CP4%2CEK%2CSQ%2CQR%2CTK%2CLH%2CUR%2CQI%2CAW&pastWindow=4&futureWindow=12&maxFlights=100&lateMinutes=15&showCargo=false&includeCodeShares=true';

        try {
            const response = await axios.get(apiUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }

            });
            // console.log('Type of fetched data:', typeof response.data);
            console.log('Data fetched successfully de:', response.data);

            let returnArray = [];
            let temp = [...response.data["flightRecords"].filter(val => filter_str.findIndex((str) => str === val.airlineName) !== -1)];
            let temp1 = [...city_data];
            for (let index = 0; index < temp.length; index++) {
                const element = temp[index];
                let name = element.destinationAirportCode;
                let indexNumber = temp1.findIndex(val => val["IATA code"] == name);
                if (indexNumber !== -1) {
                    let returnvalue = { ...temp[index], displayname: temp1[indexNumber]["City/Airport"] }
                    returnArray.push(returnvalue);
                    // temp[index].destinationAirportCode = temp1[indexNumber]["City/Airport"]
                }
            }
            // temp.sort(function (a, b) { return (new Date(b.scheduledTime) - new Date(a.scheduledTime)) });
            // setidata(temp);
            returnArray.sort(function (a, b) { return (new Date(b.scheduledTime) - new Date(a.scheduledTime)) });
            returnArray.reverse()
            setidata(returnArray);

        } catch (error) {
            console.error('Error fetching data:', error.response ? error.response.data : error.message);
        }
    }

    const getClientCredentialsToken = async () => {
        const tokenUrl = 'https://sitaopen.api.aero/fids/oauth/token';
        const clientId = "LyHy4YXbImZa4X0ELs0ElIlEklIPsh2E";     // Use environment variables in production
        const clientSecret = "HgWCRZVqz7p0d0kQ";

        const headers = {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': `Basic ${btoa(`${clientId}:${clientSecret}`)}`
            }
        };

        const data = 'grant_type=client_credentials';

        try {
            const response = await axios.post(tokenUrl, data, headers);
            // console.log("Access Token Retrieved Successfully:", response.data.access_token);
            console.log("token success success");

            return response.data.access_token;  // Return the access token
        } catch (error) {
            console.error('Error fetching OAuth token', error.response ? error.response.data : error.message);
            return null;  // Return null if there is an error
        }
    };



    return (
        <DepartureContext.Provider value={[idata, setidata]}>
            {children}
        </DepartureContext.Provider>
    );
};

const useDeparture = () => useContext(DepartureContext);

export { useDeparture, DepartureProvider };